import * as UI from "@iyk/ui"
import * as Route from "../../Route.ts"

import { Link } from "@remix-run/react"

export default () => {
  return (
    <div className="text-center">
      <h1 className="text-lg mb-4">Welcome to the Admin dashboard</h1>
      <div className="flex flex-col gap-2">
        <Link to={Route.chipsIngestV1} className={UI.classForLink()}>
          Ingest V1 Chips
        </Link>
        <Link to={Route.chipsIngestV2} className={UI.classForLink()}>
          Ingest V2 Chips
        </Link>
        <Link to={Route.chipsMigrate} className={UI.classForLink()}>
          Migrate Tags to Chips Tables
        </Link>
        <Link to={Route.solanaOrders} className={UI.classForLink()}>
          Solana Orders
        </Link>
      </div>
    </div>
  )
}
