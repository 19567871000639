import * as React from "react"

import { cva, type VariantProps } from "./cva.ts"

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & VariantProps<typeof classForLink>
>(({ className, ...props }, ref) => {
  return <a className={classForLink({ className })} ref={ref} {...props} />
})

Link.displayName = "Link"

export const classForLink = cva([
  "underline underline-offset-4",
  "text-gray-11",
  "hover:text-gray-12",
  "focus-visible:text-gray-12",
  "active:text-gray-11",
])
