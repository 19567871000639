/// <reference types="vite/client" />

import appleTouchIcon from "../assets/apple-touch-icon.png?url"
import faviconSvg from "../assets/favicon.svg?url"

export const favicons = [
  {
    rel: "icon",
    type: "image/svg+xml",
    href: faviconSvg,
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: appleTouchIcon,
  },
] as const
