import * as UI from "@iyk/ui"

import { LoaderResponse } from "@iyk/remix"
import { Form, Outlet } from "@remix-run/react"
import { Effect } from "effect"
import { authenticated, Remix } from "../../main.server.ts"

export const loader = Remix.loader.middleware(authenticated.loader()).handler(() =>
  Effect.gen(function* () {
    return LoaderResponse.Ok(null)
  }),
)

export default () => {
  return (
    <div>
      <header>
        <Form method="POST" action="/logout">
          <UI.Button>Logout</UI.Button>
        </Form>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  )
}
