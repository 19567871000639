import * as Sentry from "@sentry/remix"

import { useLocation, useMatches } from "@remix-run/react"
import { useEffect } from "react"

export type Config = {
  dsn: string
  environment: string
}

export const make = (config: Config) => {
  return Sentry.init({
    dsn: config.dsn,
    environment: config.environment,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],
  })
}

export const ClientScript = (props: Config) => (
  <script dangerouslySetInnerHTML={{ __html: `window.SENTRY_CONFIG = ${JSON.stringify(props)}` }} />
)
