import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as Route from "../../Route.ts"

import React from "react"

import { Cookie, LoaderResponse } from "@iyk/remix"
import { useLoaderData } from "@remix-run/react"
import { Effect, Option, Schema } from "effect"
import { Remix } from "../../main.server.ts"
import { Link } from "../../ui/link.tsx"

export const loader = Remix.loader
  .cookies(Schema.Struct({ error: Schema.optional(Schema.String) }))
  .handler(({ capabilities: { users, sessions }, cookies, cookieJar }) =>
    Effect.gen(function* () {
      const currentSession = yield* Effect.option(sessions.authenticated)

      yield* cookieJar.unset("error")

      if (Option.isSome(currentSession)) {
        return LoaderResponse.TemporaryRedirect(Route.dashboard)
      }

      const [state, url] = yield* users.createOAuthUrl

      yield* cookieJar.set(Cookie.make("state", state, { path: "/", maxAge: "20 minutes" }))

      return LoaderResponse.Ok({ url, error: cookies.error })
    }),
  )

export default () => {
  const { error, url } = useLoaderData<typeof loader>()

  const showError = () =>
    UI.toast({
      title: "Error logging in",
      description:
        "There was an error logging in with Google. Please try again and make sure to use an iyk.app email address.",
      variant: "destructive",
    })

  React.useEffect(() => {
    if (error) {
      // TODO: setTimeout should not be needed
      const timeout = setTimeout(showError)
      return () => clearTimeout(timeout)
    }
  }, [])

  return (
    <div className="flex flex-col h-full">
      <header className="p-4 -mb-8">
        <Link to={Route.dashboard} aria-label="Home">
          <Icon.IykLogo className="size-7" />
        </Link>
      </header>

      <div className="flex-1 flex flex-col items-center justify-center p-4">
        <div className="max-w-[358px] w-full text-center">
          <h1 className="text-gray-12">Log in</h1>
          <p className="pt-2.5 text-gray-11 text-sm">Use an @iyk.app email to gain access</p>

          <a
            href={url}
            className={UI.classForButton({
              variant: "primary",
              className: "my-6 w-full",
            })}
          >
            <GoogleIcon />
            Log in with Google
          </a>

          <p className="text-gray-10 text-xs">
            By continuing, you agree to the <br />
            <a
              href="https://assets.iyk.app/iyk-terms-of-service.pdf"
              className={UI.classForLink()}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://assets.iyk.app/iyk-privacy-policy.pdf"
              className={UI.classForLink()}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
      <div></div>
    </div>
  )
}

const GoogleIcon = () => (
  <svg viewBox="0 0 16 16" width={16} height={16}>
    <path d="M10.3 5.78c-.63-.6-1.42-.9-2.3-.9a3.58 3.58 0 0 0-3.36 2.48 3.6 3.6 0 0 0 0 2.28 3.6 3.6 0 0 0 5.39 1.9 2.76 2.76 0 0 0 1.2-1.81H8V7.41h5.65c.07.4.11.8.11 1.23 0 1.82-.65 3.36-1.79 4.4A5.73 5.73 0 0 1 8 14.5a6 6 0 0 1-5.36-8.7A6 6 0 0 1 12 4.07l-1.7 1.71Z" />
  </svg>
)
